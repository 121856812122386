import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import HeadingM from "../shared/HeadingM"
import Partners from "../shared/top/Partners"

const query = graphql`
  query {
    aimhigh: file(relativePath: { eq: "shared/partners/aimhigh.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    hukujusou: file(relativePath: { eq: "shared/partners/hukujusou.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    dayhome291: file(relativePath: { eq: "shared/partners/dayhome291.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    lifeness: file(relativePath: { eq: "shared/partners/lifeness.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    fukujuya: file(relativePath: { eq: "shared/partners/fukujuya2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
  }
`

const TopPartners = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      logo: getImage(data.aimhigh),
      link: "https://aimhighgroup.jp/",
    },
    {
      logo: getImage(data.hukujusou),
      link: "http://akanekai-f.com/",
    },
    {
      logo: getImage(data.dayhome291),
      link: "http://dayhome2910.jp/",
    },
    {
      logo: getImage(data.lifeness),
      link: "https://www.life-ness.jp/",
    },
    {
      logo: getImage(data.fukujuya),
      link: "https://www.fukujyuya2020.com/",
    },
  ]
  return (
    <div className="mt-24">
      <HeadingM title="提携事業所" target="one" />
      <Partners items={items} />
    </div>
  )
}

export default TopPartners
